/* set the overriding variables */
$grid-breakpoints: (
				xs: 0,
				sm: 576px,
				md: 768px,
				lg: 992px,
				xl: 1200px,
				xxl: 1800px
);

$container-max-widths: (
				xs: 0,
				sm: 576px,
				md: 768px,
				lg: 992px,
				xl: 1200px,
				xxl: 1800px
);



//== Colors
//
//## Gray and brand colors for use across Bootstrap.


$gray-dark		:             #666;
$brand-primary	:             #efefef;

$brand-red		:		#C54127;
$brand-green	:		#50C1AB;
$brand-yellow	:		#DBA127;
$brand-blue		:		#27ABC5;
$brand-orange	:		#c35f0e;

$brand-primary				:		#efefef;
$brand-primary-lighter		:       lighten($brand-primary, 18%);

$brand-secondary			:		$brand-red;
$brand-secondary-lighter	:       lighten($brand-secondary, 10%);

$brand-tertiary				:		$brand-green;
$brand-tertiary-lighter		:        lighten($brand-tertiary, 25%);

$brand-quaternary	:		$brand-yellow;
$brand-quinary		:		$brand-blue;
$brand-senary		:		$brand-orange;


.brand-primary {
	color: $brand-primary;
}

.brand-secondary {
	color: $brand-secondary;
}

.brand-tertiary {
	color: $brand-tertiary;
}

.brand-quaternary {
	color: $brand-quaternary;
}

.brand-quinary {
	color: $brand-quinary;
}

.brand-senary {
	color: $brand-senary;
}

//== Scaffolding
//
//## Settings for some of the most global styles.

$body-bg			:       white;
$text-color			:       black;

//** Global textual link color.
$link-color:            black;
//** Link hover color set via `darken()` function.
$link-hover-color:      darken($link-color, 15%);
//** Link hover decoration.
$link-decoration: underline;
//** Link hover decoration.
$link-hover-decoration: underline;



//== Social Colors

$facebook			:		$brand-primary;
$facebook-dark		: 		darken($facebook, 25%);
$twitter			:		$brand-primary;
$twitter-dark		: 		darken($twitter, 25%);
$google				:		$brand-primary;
$google-dark		: 		darken($google, 25%);
$instagram			:		$brand-primary;
$instagram-dark		: 		darken($instagram, 25%);
$linkedin			:		$brand-primary;
$linkedin-dark		: 		darken($linkedin, 25%);
$pinterest			:		$brand-primary;
$pinterest-dark		: 		darken($pinterest, 25%);
$youtube			:		$brand-primary;
$youtube-dark		: 		darken($pinterest, 25%);

