@include media-breakpoint-down(md) {

  .vanitem h3 {
      font-size: 1.6em;
    }

  .vanitem h4 {
    font-size: 1.1em;
  }



}

@include media-breakpoint-up(md) {

  h1 {
    font-size: 2.2rem;
  }
  h2 {
    font-size: 2.0rem;
  }
  h3 {
    font-size: 1.6rem;
  }
  .top-bottom-buffer {
    padding-top: 60px;
  }
  .top-bottom-buffer, .bottom-buffer {
    padding-bottom: 60px;
  }
  .owl-vantypes .vanitem {
    height: 100px;
  }
  ul.technical-stuff li {
    margin-left:0em;
    margin-right:2em;
    -webkit-column-break-inside:avoid;
    -moz-column-break-inside:avoid;
    -o-column-break-inside:avoid;
    -ms-column-break-inside:avoid;
    column-break-inside:avoid;
  }

   ul.technical-stuff {
    list-style:outside disc;
    padding:0.5em;
    margin-top:1em;
    -webkit-column-count:2;
    -webkit-column-gap:0.5em;
    -moz-column-count:2;
    -moz-column-gap:0.5em;
    -o-column-count:2;
    -o-column-gap:0.5em;
    column-count:2;
    column-gap:0.5em;
  }
  .AccordionHeading a {
    font-size: 1.2em;
  }

}

@include media-breakpoint-up(lg) {

  .btn-navigation {
    margin-left: 10px;
  }
  .owl-vantypes .vanitem {
    height: 800px;
  }

  .set-up-camp {
    min-height: 680px;
  }

}

@include media-breakpoint-up(xl) {

  h2 {
    margin-bottom: 20px;
  }

  h3 {
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .owl-vantypes .vanitem {
    min-height: 1100px;
  }

  .set-up-camp {
    min-height: 720px;
  }

  .owl-vantypes .nav-btn {
    top: 50% !important;
  }

  .owl-vantypes .nav-btn {
    font-size: 200%;
  }

  .owl-vantypes .prev-slide {
    left: -73px !important;
  }

  .owl-vantypes .next-slide {
    right: -73px !important;
  }

}

