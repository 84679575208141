
//Jumbotrons

// 2500 = 2168 by 1214 pixels
// 800 = 800 by 448 pixels
// 600 = 600 by 350 pixels

.jumbotron-text {
  position:absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: rgba(0, 0, 0, .4);
  padding: 10px;
}

.jumbotron {
  background-image: url('/assets/campervan-hire-mountains-to-sea-600.jpg');
  background-size: cover;
  padding-top: 50%; /* Aspect Ratio */
  position: relative; /* If you want text inside of it */
  color: white;
  background-repeat: no-repeat;
}

.jumbotron h1 {
  color: white;
}

.home-jumbotron {
  background-image: url('/assets/campervan-hire-mountains-to-sea-600.jpg');
}

.baxter-jumbotron {
  background-image: url('/assets/baxter-campervan-hire-600.jpg');
}

.baxter-rooftop-jumbotron {
  background-image: url('/assets/baxter-rooftop-campervan-hire-600.jpg');
}

.calicoast-jumbotron {
  background-image: url('/assets/coastal-campervan-hire-600.jpg');
}

.calibeach-jumbotron {
  background-image: url('/assets/beach-campervan-hire-600.jpg');
}

.minibus-jumbotron {
  background-image: url('/assets/minibus-jumbo-600.jpg');
}

/* Locations */

.bordeaux-jumbotron {
  background-image: url('/assets/bordeaux-campervan-hire-600.jpg');
}

.avignon-jumbotron {
  background-image: url('/assets/avignon-campervan-hire-600.jpg');
}

.biarritz-jumbotron {
  background-image: url('/assets/biarritz-campervan-hire-600.jpg');
}

.annecy-jumbotron {
  background-image: url('/assets/annecy-campervan-hire-600.jpg');
}

.marseille-jumbotron {
     background-image: url('/assets/marseille-campervan-hire-600.jpg');
}


// Media queries

@include media-breakpoint-up(md) {

  // Jumbotrons (medium)

  .jumbo-text-desktop {
    font-size: 2.6em;
  }

  .jumbotron-text {
    padding: 20px;
  }

  .jumbotron {
    padding-top: 60%; /* Aspect Ratio */
  }

  .jumbo-jumbotron {
    background-image: url('/assets/campervan-hire-mountains-to-sea-800.jpg');
  }

  .baxter-jumbotron {
    background-image: url('/assets/baxter-campervan-hire-800.jpg');
  }

  .baxter-rooftop-jumbotron {
    background-image: url('/assets/baxter-rooftop-campervan-hire-800.jpg');
  }

  .minibus-jumbotron {
    background-image: url('/assets/minibus-jumbo-800.jpg');
  }

  .calicoast-jumbotron {
    background-image: url('/assets/coastal-campervan-hire-800.jpg');
  }

  .calibeach-jumbotron {
    background-image: url('/assets/beach-campervan-hire-800.jpg');
  }

  /* Locations */

  .bordeaux-jumbotron {
    background-image: url('/assets/bordeaux-campervan-hire-800.jpg');
  }

  .avignon-jumbotron {
    background-image: url('/assets/avignon-campervan-hire-800.jpg');
  }

  .biarritz-jumbotron {
    background-image: url('/assets/biarritz-campervan-hire-800.jpg');
  }

  .annecy-jumbotron {
    background-image: url('/assets/annecy-campervan-hire-800.jpg');
  }

  .marseille-jumbotron {
    background-image: url('/assets/marseille-campervan-hire-800.jpg');
  }

}

@include media-breakpoint-up(lg) {

  .jumbo-text-desktop {
    font-size: 3.4em;
  }

  .jumbo-text-adventure {
    font-size: 2rem;
  }

  .jumbotron {
    padding-top: 40%; /* Aspect Ratio */
  }

  .home-jumbotron {
    background-image: url('/assets/campervan-hire-mountains-to-sea-2500.jpg');
  }

  .baxter-jumbotron  {
    background-image: url('/assets/baxter-campervan-hire-2500.jpg');
  }

  .baxter-rooftop-jumbotron {
    background-image: url('/assets/baxter-rooftop-campervan-hire-2500.jpg');
  }

  .minibus-jumbotron {
    background-image: url('/assets/minibus-jumbo-2500.jpg');
  }

  .calicoast-jumbotron {
    background-image: url('/assets/coastal-campervan-hire-2500.jpg');
  }

  .calibeach-jumbotron {
    background-image: url('/assets/beach-campervan-hire-2500.jpg');
  }

  /* Locations */

  .bordeaux-jumbotron {
    background-image: url('/assets/bordeaux-campervan-hire-2500.jpg');
  }

  .avignon-jumbotron {
    background-image: url('/assets/avignon-campervan-hire-2500.jpg');
  }

  .biarritz-jumbotron {
    background-image: url('/assets/biarritz-campervan-hire-2500.jpg');
  }

  .annecy-jumbotron {
    background-image: url('/assets/annecy-campervan-hire-2500.jpg');
  }

  .marseille-jumbotron {
    background-image: url('/assets/marseille-campervan-hire-2500.jpg');
  }


}

@include media-breakpoint-up(xl) {

  .jumbo-text-desktop {
    font-size: 4em;
  }

}
