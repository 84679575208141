// --------------------------------------------------
//
// Fonts
//
// --------------------------------------------------

// Font Stacks

$font-url-google: 'https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,600;1,400&family=Zilla+Slab:ital,wght@0,400;0,600;0,700;1,400&display=swap';

$font-family-body: 'Montserrat', sans-serif;
$headings-font-family: 'Zilla Slab', serif;
$font-family-base: $font-family-body;

body {
  color: $text-color;
  font-family: $font-family-base;
}

h1, h2, h3, h4, h5 {
  font-family: $headings-font-family;
  font-weight: bold;
}

p {
  line-height: 2em;
}

//==== Fonts

$base__font-size: 15px;
$base__line-height: 34px;


h1 {
  font-size: 2.0rem;
  margin-bottom: 10px;
  color: $brand-quinary;
}

h2 {
  font-size: 1.8rem;
  margin-bottom: 10px;
  color: $brand-quinary;
}

h3 {
  font-size: 1.6rem;
  color: $brand-quinary;
}

.useful-information h2, .useful-information h3, .useful-information .fa-circle {
  color: $brand-tertiary;
}

.locations h3, .locations h4 {
  color: $brand-secondary;
}

.tertiary-section h2, .dark-section h2, .dark-section h3 {
  color: white;
}

.nearby {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;
  line-height: 2em;
}


.jumbo-text-desktop {
  font-family: $headings-font-family;
  font-size: 4rem;
}

.jumbo-text-mobile {
  font-family: $headings-font-family;
  font-size: 1.4rem;
}

.jumbo-text-adventure {
  font-size: 1rem;
}

.increasesize {
  font-size: 130%;
}

.adventure-quote {
  font-family: $headings-font-family;
  font-size: 3em;
  color: $brand-secondary;
}
