

/* Buttons */

.btn-outline-primary, .btn-primary   {
	border: 1px solid $brand-primary;
}

.btn-primary  {
	background-color: $brand-primary;
}

.btn-outline-secondary, .btn-secondary  {
	border: 1px solid $brand-secondary;
}

.btn-secondary {
	background-color: $brand-secondary;
}

.btn-outline-tertiary, .btn-tertiary  {
	border: 1px solid $brand-tertiary;
}

.btn-tertiary {
	background-color: $brand-tertiary;
}

.btn-outline-quaternary,.btn-quaternary {
	border: 1px solid $brand-quaternary;
}

.btn-quaternary {
	background-color: $brand-quaternary;
}

.btn-outline-quinary, .btn-quinary {
	border: 1px solid $brand-quinary;
}

.btn-quinary {
	background-color: $brand-quinary;
}

.btn-outline-senary, .btn-senary {
	border: 1px solid $brand-senary;
}

.btn-senary {
	background-color: $brand-senary;
}

.btn-outline-primary, .btn-outline-secondary, .btn-outline-tertiary, .btn-outline-quaternary, .btn-outline-quinary, .btn-outline-senary {
	color: black;
}

.btn-primary, .btn-secondary, .btn-tertiary, .btn-quaternary, .btn-quinary, .btn-senary {
	color: white;
	text-decoration: none;
}

/*Accept cookies, button first*/

.acceptcookies {
	background-color: $brand-secondary;
	color: white;
	border: white;
	&:hover {
		background-color: $gray-dark;
	}
}

.alert a {
	color: white;
}


/***** Zoom *****/

.zoom a:hover{
	transform: scale(1.1);
	transition: all ease 500ms;
}