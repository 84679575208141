.container-fluid {
  padding: 0px;
}
.top-bottom-buffer {
  padding-top: 30px;
}
.top-bottom-buffer, .bottom-buffer {
  padding-bottom: 30px;
}
footer {
  border-top: 1px $brand-primary solid;
}

/* Navigation */

.dropdown-menu {
  margin-bottom: 20px;
}

.BonfireMenu {
  font-size: 1.6em;
  margin-right: 2px;
  margin-top: -2px;
}

.btn-navigation {
  text-decoration: none;
  border: 1px solid black;
  margin-bottom: 10px;
  border-radius: 0px;
}

.dropdown-item {
  text-decoration: none;
}

/* Full width sections */

.light-section {
  background-color: $brand-primary;
}

.dark-section  {
  background-color: $gray-dark;
  color: white;
}

.secondary-section {
  background-color: $brand-secondary;
  color: white;
  }

.tertiary-section {
  background-color: $brand-tertiary;
  color: white;
  }

.dark-section .card,
.secondary-section .card,
.tertiary-section .card {
  color: black;
}

.custom-colors {
  background-color: transparent;
  color: $brand-primary;
  font-size: 90%;
  padding: 10px;
  border: black 1px solid;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.campervan-image {
  box-shadow: 1px 2px 4px rgba(204, 204, 204, .7);
  border: 8px white solid;
}

.whyrent-inner, .camper-highlights-inner {
  background-color: $brand-primary;
  padding: 30px;
  height: 100%;
}

.whyrent, .camper-highlights {
  margin-bottom: 30px;
}

.whyrent .fal, .camper-highlights .fal {
  margin-bottom: 20px;
}

.whyrent h5, .camper-highlights-inner h5 {
  font-size: 1.8rem;
}


/* VAN PAGES =============================================================== */

#camperhirecarousel {

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    background-image: none;
    font-family: 'Font Awesome\ 5 Pro';
    color: $brand-primary;
    font-size: 32px;
  }

  .carousel-control-prev,
  .carousel-control-next {
    bottom: 92%;
  }

  .carousel-control-prev-icon:after {
    content: "\f33a";
  }

  .carousel-control-next-icon:before {
    content: "\f33b";
  }

}


/* Unordered lists on vans pages */

ul.vaninfo {
  list-style: none;
  margin-left: -30px;
}

.vaninfo li {
   border-bottom: 1px solid $brand-primary;
}

.vaninfo li:last-child {
  border-bottom: none
}


/* Minibuses */

.vehiclecard {
  background: $brand-primary-lighter;
  color: black;
}

.vehiclecard:nth-child(even) {
  background-color: $brand-primary;
}

.vehiclecard .card-body {
  padding: 15px 10px 15px 10px;
}

.set-up-camp {
  min-height: 720px;
}

.inside-set-up {
  background-color: $gray-100;
  border-radius: 20px;
  padding: 20px 10px 20px 10px;
  position: absolute;
  bottom:0;
  top:0;
}

.setup-camp-visit-button {
  position: absolute;
  bottom: 30px;
}



/* Owl carousel van types*/

.vanitem {
  background-color: white;
  padding: 20px;
}

.vanitem h3 a:link {
  text-decoration: none;
}

.vanitem ul {
  margin-left: -20px;
}

.owl-vantypes .vanitem {
  margin-left: 10px;
  margin-right: 10px;
  min-height: 920px;
}

.owl-vantypes .item a:link, footer a {
  text-decoration: none;
}

.owl-vantypes .item img {
  margin-bottom: 20px;
}

.van-item-button {
  position: absolute;
  bottom:0;
  left: 0;
  right: 0;
}



.vantypes-box {
  float:left;
  border: 1px #fff solid;
  border-right-width: 0;
  padding: 10px;
}

.vantypes-box:last-child {
  border-right-width: 1px;
}

.vantypes-box:hover {
  border: 1px #efefef solid;
}

.vantypes-box:hover + .item {
  border-left-width: 0;
}


/* Insurance and extras page +++++++++++++++++++++++++++++++++++++++++++ */

.price-per-day, .per-day {
  display: block;
  text-align: center;
}

.price-per-day {
  font-size: 250%;
}

.insurance-pack h5, .extras-pack h5 {
  font-family: $font-family-body;
  text-align: center;
}


/* INSURANCE PACKS +====================================================+ */

.insurance-pack {
  background-color: $brand-quinary;
  color: white;
}

ul.insurance-text {
  margin-left: -20px;
  text-align: left;
}


/* EXTRAS +====================================================+ */


.extras-inside {
  background-color: $brand-secondary;
  color: white;
  padding: 20px 5px;
  margin: 20px 2px;
}


/* Polaroids +====================================================+ */


.polaroids {
  text-align: center;
}
.polaroids a {
  display: inline-block;
  text-decoration: none;
  padding: 10px 10px 25px 10px;
  color: #8B8B8B;

  -moz-box-shadow: 0px 1px 5px 0px #656565;
  -webkit-box-shadow: 0px 1px 5px 0px #656565;
  -o-box-shadow: 0px 1px 5px 0px #656565;
  box-shadow: 0px 1px 5px 0px #656565;

  -webkit-transform: rotate(-1deg);
  -moz-transform: rotate(-1deg);
  -o-transform: rotate(-1deg);
  tranform: rotate(-1deg);

  -webkit-transition: all .5s ease-in-out;
  -moz-transition: all .5s ease-in-out;
  -ms-transition: all .5s ease-in-out;
  -o-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;

  -moz-opacity: 0.85;
  -khtml-opacity: 0.85;
  opacity: 0.85;
  -ms-filter:"progid:DXImageTransform.Microsoft.Alpha"(Opacity=85);
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=85);
  filter:alpha(opacity=85);
}
.polaroids a:hover {
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
  -ms-filter:"progid:DXImageTransform.Microsoft.Alpha"(Opacity=100);
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
  filter:alpha(opacity=100);
}
.polaroids a:nth-child(odd) {
  -webkit-transform: rotate(1deg);
  -moz-transform: rotate(1deg);
  -o-transform: rotate(1deg);
  tranform: rotate(1deg);
}
.polaroids a:after {
  content: attr(title);
}
.polaroids img {
  border: 1px solid #C9C9C9;
}

.camper-dimensions {
  border: 4px solid #fff;
}

.thick-line {
  border: 10px solid #fff;
}



/*
*
* ==========================================
* Accordion
* ==========================================
*
*/
/* Horizontal line */
.collapsible-link::before {
  content: '';
  width: 18px;
  height: 4px;
  background: $brand-tertiary;
  position: absolute;
  top: calc(50% - 1px);
  right: 1rem;
  display: block;
  transition: all 0.3s;
}

/* Vertical line */
.collapsible-link::after {
  content: '';
  width: 4px;
  height: 18px;
  background: $brand-tertiary;
  position: absolute;
  top: calc(50% - 8px);
  right: calc(1rem + 7px);
  display: block;
  transition: all 0.3s;
}

.collapsible-link[aria-expanded='true']::after {
  transform: rotate(90deg) translateX(-1px);
}

.collapsible-link[aria-expanded='true']::before {
  transform: rotate(180deg);
}

.accordion {
  margin-bottom: 40px;
}
.accordion ul li {
  color: $gray-800;
  margin-bottom: 20px;
}

.accordion .card {
  border: 1px solid $brand-tertiary;
}

.AccordionHeading a {
  font-size: 1.0em;
  text-decoration: none;
  color: $brand-tertiary !important;
    &:hover {
      color: $brand-tertiary-lighter !important;
    }
}

